<template lang="pug">
div(class='SliderCases')
	UiCarousel(
		class='SliderCases--Carousel',
		:perView='greaterThan("mobileWide") ? 2.4 : greaterThan("mobileMedium") ? 1.6 : 1.2',
		ref='slider'
	)
		template(#default='{ carouselItemProps }')
			TransitionGroup(name='slider')
				UiCarouselItem(v-for='item in cases', :key='item.id', v-bind='carouselItemProps')
					div(class='SliderCases--Carousel--Item')
						UiImage(:image='item', width='400px', lazy)
</template>

<script lang="ts" setup>
import { UiCarousel } from '#components';
import { Image } from '~/models/image';

/**
 * Define refs
 */
const slider = ref<InstanceType<typeof UiCarousel> | null>(null);

/**
 * Define hooks
 */
const { greaterThan } = usePlatform();

/**
 * Define props
 */
const { cases = [] } = defineProps<{
	cases: Image.Model[];
}>();
</script>

<style lang="less" scoped>
.SliderCases {
	.box(grid; 100%; auto; none; 1rem; auto; 100%; center; start);

	&--Carousel {
		.grid(1 span, 1 span);
		.box(block; 100%; auto; none);

		&--Item {
			.box(grid; 100%; 100%; none; 1rem; auto 1fr; auto; stretch; start);
			& > img {
				object-fit: cover;
				.grid-aspect(2.65; 2);
				.box(block; 100%; 100%);
				.border-radius(@BorderRadiusSmall);
			}
		}
	}
}
</style>
